/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var UIUtil = require('psa-core/util/ui');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var PanelGridView = require('common/views/PanelGridView');
var MomentPanelCellView = require('common/views/PanelGridView/MomentPanelCellView');
var ButtonsPanelCellView = require('common/views/PanelGridView/ButtonsPanelCellView');

var NonDepEntityWithdrawApplicationModalView = require('./NonDepEntityWithdrawApplicationModalView');

var tmplNonDepEntityActiveApplicationView = require('../templates/NonDepEntityActiveApplicationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityActiveApplicationView,

  className: 'nondep-entity-active-application-view',

  ui: {
    activeApplicationsContainer: '.active-applications-container'
  },

  regions: {
    activeApplicationsContainer: '@ui.activeApplicationsContainer'
  },

  templateHelpers: function() {
    return {
      viewMode: this.options.viewMode
    };
  },

  onBeforeShow: function() {
    var baseOptions = {
      columns: [
        {
          name: 'applicationNumber',
          label: 'Application Number',
          width: 10,
          overflow: 'truncate'
        },
        {
          name: 'applicationType',
          label: 'Application Type',
          width: this.options.viewMode === 'int' ? 20 : 25,
          overflow: 'truncate'
        },
        {
          name: 'licenseType',
          label: 'License Type',
          width: this.options.viewMode === 'int' ? 15 : 15,
          overflow: 'truncate'
        },
        {
          name: 'applicationStatus',
          label: 'Application Status',
          width: this.options.viewMode === 'int' ? 15 : 15,
          overflow: 'truncate'
        },
        {
          name: 'submissionDate',
          label: 'Date Submitted',
          width: this.options.viewMode === 'int' ? 15 : 15,
          panelCell: MomentPanelCellView
        }
      ],
      emptyText: 'No Active Applications.',
      collection: new PageableCollection(this.model.get('activeApplications'), { pageSize: 20 })
    };

    var externalManagerAccessLevelId = 20;
    if (this.options.viewMode === 'ext' && this.options.userAccessLevel.accessLevelId >= externalManagerAccessLevelId) {
      baseOptions.columns.push({
        name: '',
        label: '',
        renderable: true,
        width: 10,
        panelCell: ButtonsPanelCellView.extend({
          buttons: [
            {
              buttonText: 'View',
              href: function(model) {
                return '#ext/nondep/application/' + model.get('id') + '/view';
              },
              buttonClass: 'btn-primary btn-md m-t-xs'
            }
          ]
        })
      });
      baseOptions.columns.push({
        name: '',
        label: '',
        renderable: function(dataModel) {
          var applicationCategoryId = dataModel.get('applicationCategoryId');
          var isNewApplication = applicationCategoryId === '1';
          var isAmendment = applicationCategoryId === '3';
          if (isNewApplication || isAmendment) {
            return true;
          } else {
            return false;
          }
        },
        panelCell: ButtonsPanelCellView.extend({
          buttons: [
            {
              buttonText: 'Withdraw',
              onClick: this.onClickWithdrawApplication.bind(this),
              buttonClass: 'btn-danger btn-md m-t-xs'
            }
          ]
        })
      });
    } else if (this.options.viewMode === 'int') {
      baseOptions.columns.push({
        name: 'owner',
        label: 'Owner',
        width: 10,
        overflow: 'truncate'
      });
      baseOptions.columns.push({
        name: 'claimedDate',
        label: 'Date Claimed',
        width: 10,
        panelCell: MomentPanelCellView
      });
      baseOptions.columns.push({
        name: '',
        label: '',
        width: 4,
        panelCell: ButtonsPanelCellView.extend({
          buttons: [
            {
              buttonText: 'View',
              buttonClass: 'btn-primary btn-md',
              href: function(model) {
                return '#nondep/application/' + model.get('id') + '/dashboard';
              }
            }
          ]
        })
      });
    }
    var activeApplications = new PanelGridView(baseOptions);
    this.showChildView('activeApplicationsContainer', activeApplications);
  },

  onClickWithdrawApplication: function(e, model) {
    e.preventDefault();

    var entityId = model.get('entityId');
    UIUtil.showModal({
      title: 'Withdraw Application',
      view: new NonDepEntityWithdrawApplicationModalView({ model: model })
    }).done(function() {
      Radio.channel('navigate').trigger('show:ext:nd:entity-dashboard', entityId);
    });
  }
});
