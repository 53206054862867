var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<h1 class=\"page-title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<form class=\"form-horizontal update-payment-schedule-form\">\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Billed Examiner Hours</label>\r\n    <div class=\"col-md-8\">\r\n      <div class=\"billed-hours-container\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Total Payment Due</label>\r\n    <div class=\"col-md-3\">\r\n      <div class=\"input-group\">\r\n        <span class=\"input-group-addon\">$</span>\r\n        <input type=\"number\" step=\"any\" name=\"totalPaymentDue\" class=\"form-control total-payment-due\"\r\n          placeholder=\"Amount\" data-parsley-required=\"true\" data-parsley-dollar-amount=\"true\"\r\n          data-parsley-errors-container=\".amount-error-container\" data-parsley-strict-minimum=\"0\"\r\n          value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalAmountDue : stack1), depth0))
    + "\" />\r\n      </div>\r\n      <div class=\"amount-error-container\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3\">Potential Fees Incurred</label>\r\n    <div class=\"col-md-3 form-control-static total-fees-incurred\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalFeesIncurred : stack1), depth0))
    + "</div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-md-3 \">Total Payment Made</label>\r\n    <div class=\"col-md-3 form-control-static total-paid-amount\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.paymentSums : depth0)) != null ? stack1.totalPaidAmount : stack1), depth0))
    + "</div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-md-3\">Payment Plan</label>\r\n    <div class=\"col-md-8\">\r\n      <div class=\"payment-plan-container\"></div>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-md-8 col-md-offset-3\">\r\n      <div class=\"alert alert-danger collapse payment-amount-error\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"\r\n          aria-hidden=\"true\"></i>Value entered for <strong>Total Payment Due</strong> must equal the sum of all payments\r\n        in the payment plan. </div>\r\n      <div class=\"alert alert-danger collapse fee-amount-error\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"\r\n          aria-hidden=\"true\"></i><strong>Potential Fees Incurred</strong> cannot be greater than $450.00. </div>\r\n    </div>\r\n  </div>\r\n</form>\r\n\r\n<div class=\"row m-b-lg\">\r\n  <div class=\"col-md-6 col-md-offset-3\">\r\n    <a href=\"#nondep/dashboard/exam/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.exam : depth0)) != null ? stack1.examId : stack1), depth0))
    + "\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n    <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n      <span class=\"ladda-label\">Save Exam Invoice</span>\r\n    </button>\r\n  </div>\r\n</div>";
},"useData":true});