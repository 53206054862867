/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var moment = require('moment');

var formatAsDollars = require('common/util/formatAsDollars');

var BreadcrumbView = require('common/views/BreadcrumbView');
var InvoiceGridView = require('common/views/InvoiceGridView');
var ExamHeaderBarView = require('../../common/views/ExamHeaderBarView');
var PaymentScheduleGridView = require('../../common/views/PaymentScheduleGridView');

var SubmitUpdateExamPayment = require('../services/SubmitUpdateExamPayment');

var tmplNonDepInternalExamUpdatePaymentScheduleView = require('../templates/NonDepInternalExamUpdatePaymentScheduleView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepInternalExamUpdatePaymentScheduleView,

  className: 'non-dep-internal-exam-update-payment-schedule-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    updatePaymentScheduleForm: '.update-payment-schedule-form',
    submit: '.submit',
    paymentGridContainer: '.payment-plan-container',
    billedHoursContainer: '.billed-hours-container',
    paymentAmount: 'input[name="totalPaymentDue"]',
    totalFeesIncurred: 'div[name="totalFeesIncurred"]',
    paymentAmountErrorAlert: '.payment-amount-error',
    feeAmountErrorAlert: '.fee-amount-error'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    billedHoursContainer: '@ui.billedHoursContainer',
    paymentGridContainer: '@ui.paymentGridContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeRender: function() {
    var totalAmountDue = _.reduce(
      this.model.get('examPayments'),
      function(memo, payment) {
        var amount = parseFloat(payment.amount);
        var feeAmount = parseFloat(payment.feeAmount);
        memo += amount ? parseFloat(amount) : 0;
        memo += feeAmount ? parseFloat(feeAmount) : 0;
        return memo;
      },
      0
    );
    this.model.set('paymentSums', {});
    this.model.get('paymentSums').totalAmountDue = totalAmountDue.toFixed(2);
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    this.paymentsToDelete = [];

    this.showChildView(
      'headerBar',
      new ExamHeaderBarView({
        model: new Backbone.Model(_.extend(exam, { title: 'Exam' }))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: exam.entityName,
            link: '#nondep/entity/' + exam.entityId + '/dashboard'
          },
          {
            text: 'License #' + exam.licenseNumber,
            link: '#nondep/entity/' + exam.entityId + '/license/' + exam.licenseId
          },
          {
            text: 'Exam #' + exam.examId,
            link: '#nondep/dashboard/exam/' + exam.examId
          },
          {
            text: 'Update Exam Invoice'
          }
        ]
      })
    );

    this.invoiceGrid = new InvoiceGridView({
      emptyText: 'No Exam Billing Items Found',
      collection: new Backbone.Collection(this.model.get('examInvoiceItems'))
    });
    this.showChildView('billedHoursContainer', this.invoiceGrid);

    this.$('.refund-amount-override').val(exam.refundAmountOverride);

    this.paymentGrid = new PaymentScheduleGridView({
      emptyText: 'No Payment(s) Scheduled',
      collection: new Backbone.Collection(this.model.get('examPayments')),
      isReadOnly: false,
      showFees: true,
      onDeletePayment: this.onDeletePayment.bind(this),
      onEditPayment: this.onEditPayment.bind(this),
      onAddCompletedPayment: this.onAddCompletedPayment.bind(this),
      onAddPayment: this.onAddPayment.bind(this),
      mode: 'adhoc',
      showCompletedButton: true,
      defaultDueDate: moment().add(30, 'days')
    });
    this.showChildView('paymentGridContainer', this.paymentGrid);

    this.refreshPaymentSums();
  },

  onAddPayment: function(payment) {
    var defer = $.Deferred();

    this.paymentGrid.collection.add(payment);
    this.refreshPaymentSums();

    defer.resolve();
    return defer.promise();
  },

  onAddCompletedPayment: function(payment) {
    var defer = $.Deferred();

    this.paymentGrid.collection.add(payment);
    this.refreshPaymentSums();

    defer.resolve();
    return defer.promise();
  },

  onEditPayment: function(payment) {
    var defer = $.Deferred();

    var paymentModel = _.findWhere(this.paymentGrid.collection.models, { id: payment.paymentId });
    paymentModel.set(payment);
    this.refreshPaymentSums();

    defer.resolve();
    return defer.promise();
  },

  onDeletePayment: function(payment) {
    var defer = $.Deferred();
    if (payment.get('id')) {
      this.paymentsToDelete.push(payment.get('id'));
    }
    this.paymentGrid.collection.remove(payment);
    this.refreshPaymentSums();
    defer.resolve();
    return defer.promise();
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var l = Ladda.create(this.ui.submit[0]);
        l.start();
        var formData = Syphon.serialize(this);
        var exam = this.model.get('exam') || {};
        var examId = exam.examId;

        _.extend(formData, {
          examId: examId
        });

        formData.paymentSchedule = _.map(this.paymentGrid.collection.toJSON(), function(payment) {
          return payment;
        });

        // get only payments that are still scheduled (1), due (2), or added payments with no status yet
        formData.paymentSchedule = _.filter(formData.paymentSchedule, function(payment) {
          return payment.statusId === '1' || payment.statusId === '2' || payment.statusId === undefined;
        });

        SubmitUpdateExamPayment(formData)
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard:exams:dashboard', examId);
          })
          .fail(function() {
            l.stop();
          });
      }.bind(this)
    );
  },

  validate: function(formData) {
    return $.when(this.ui.updatePaymentScheduleForm.parsley().whenValidate(), this.paymentAmountValidate());
  },

  paymentAmountValidate: function(formData) {
    var defer = $.Deferred();
    this.ui.paymentAmountErrorAlert.hide();
    this.ui.feeAmountErrorAlert.hide();

    var totalPaymentScheduled = 0;
    var totalFees = 0;
    this.paymentGrid.collection.each(function(paymentModel) {
      var amount = paymentModel.get('amount');
      if (amount) {
        totalPaymentScheduled += parseFloat(amount);
      }
      var feeAmount = paymentModel.get('feeAmount');
      if (feeAmount) {
        totalPaymentScheduled += parseFloat(feeAmount);
        totalFees += parseFloat(feeAmount);
      }
    });

    totalPaymentScheduled = parseFloat(totalPaymentScheduled).toFixed(2);

    if (totalPaymentScheduled === parseFloat(this.ui.paymentAmount.val()).toFixed(2) && totalFees <= 450) {
      defer.resolve();
    } else {
      if (totalPaymentScheduled !== parseFloat(this.ui.paymentAmount.val()).toFixed(2)) {
        this.ui.paymentAmountErrorAlert.show();
      }
      if (totalFees > 450) {
        this.ui.feeAmountErrorAlert.show();
      }
      defer.reject();
    }

    return defer.promise();
  },

  setPaymentSums: function() {
    if (this.model.get('paymentSums') === null) {
      this.model.set('paymentSums', {});
    }
    var paidStatuses = ['3', '4', '5'];
    var paidStatusDescriptions = ['Paid - Ontime', 'Paid - Late', 'Paid - Manually Added'];

    var totalPaidAmount = _.reduce(
      this.paymentGrid.collection.models,
      function(totalPaid, paymentModel) {
        if (
          _.contains(paidStatuses, paymentModel.get('statusId')) ||
          _.contains(paidStatusDescriptions, paymentModel.get('paymentStatus'))
        ) {
          totalPaid += +paymentModel.get('amount');
          totalPaid = paymentModel.get('feeAmount') ? totalPaid + +paymentModel.get('feeAmount') : totalPaid;
        }
        return totalPaid;
      },
      0
    );
    this.model.get('paymentSums').totalPaidAmount = formatAsDollars(totalPaidAmount);

    var totalFeesIncurred = _.reduce(
      this.paymentGrid.collection.models,
      function(memo, paymentModel) {
        if (paymentModel.get('statusId') === '2') {
          var feeAmount = parseFloat(paymentModel.get('feeAmount'));
          memo += feeAmount ? parseFloat(feeAmount) : 0;
        }
        return memo;
      },
      0
    );
    this.model.get('paymentSums').totalFeesIncurred = formatAsDollars(totalFeesIncurred);

    // var totalAmountDue = _.reduce(
    //   this.paymentGrid.collection.models,
    //   function(memo, paymentModel) {
    //     var amount = parseFloat(paymentModel.get('amount'));
    //     var feeAmount = parseFloat(paymentModel.get('feeAmount'));
    //     memo += amount ? parseFloat(amount) : 0;
    //     memo += feeAmount ? parseFloat(feeAmount) : 0;
    //     return memo;
    //   },
    //   0
    // );

    // this.model.get('paymentSums').totalAmountDue = formatAsDollars(totalAmountDue);
  },

  refreshPaymentSums: function() {
    this.setPaymentSums();
    // this.$('.total-amount-due').text(this.model.get('paymentSums').totalAmountDue);
    this.$('.total-fees-incurred').text(this.model.get('paymentSums').totalFeesIncurred);
    this.$('.total-paid-amount').text(this.model.get('paymentSums').totalPaidAmount);
  }
});
