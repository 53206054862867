/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');
var GetUsersForRole = require('common/services/GetUsersForRole');

var GetAllEntityLookups = require('../../common/services/GetAllEntityLookups');
var GetEntity = require('../services/GetEntity');

var DepEntityEditMainView = require('../views/DepEntityEditMainView');

module.exports = function(entityId) {
  NProgress.start();
  NProgress.set(0.4);

  $.when(GetEntity(entityId), GetUsersForRole('DP - Case Manager - Bank'))
    .done(function(entity, caseManagers) {
      NProgress.set(0.75);
      GetAllEntityLookups(entity.entityCategoryId)
        .done(function(lookups) {
          NavUtils.navigate('#dep/entity/' + entityId + '/edit-main');
          Scaffold.content.show(
            new DepEntityEditMainView({
              viewMode: 'int',
              model: new Backbone.Model({
                entity: entity,
                entityTypes: lookups.entityTypes,
                charterTypes: lookups.charterTypes,
                ownershipStructures: lookups.ownershipStructures,
                FOMTypes: lookups.FOMTypes,
                corporateStuctures: lookups.corporateStructures,
                statuses: lookups.statuses,
                federalRegulators: lookups.federalRegulators,
                FFDeptActions: lookups.FFDeptActions,
                divisions: lookups.divisions,
                fieldSupervisors: lookups.fieldSupervisors,
                caseManagers: caseManagers,
                nextExamConductedBys: lookups.nextExamConductedBys,
                nextExamTypes: lookups.nextExamTypes
              })
            })
          );
          NProgress.done();
        })
        .fail(function(err) {
          NProgress.done();
        });
    })
    .fail(function(err) {
      NProgress.done();
    });
};
