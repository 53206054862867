/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');
var moment = require('moment');
var _ = require('underscore');

var formatAsDollars = require('common/util/formatAsDollars');

var GetEntityIdentifiers = require('../../../common/services/GetEntityIdentifiers');
var GetExamDetailsForInternal = require('../../../common/services/GetExamDetailsForInternal');
var GetExamDetailsForEntity = require('../../../common/services/GetExamDetailsForEntity');
var GetExamPayment = require('../../services/GetExamPayment');
var GetExamPaymentsByExamId = require('../../../exam-dashboard/services/GetExamPaymentsByExamId');
var GetAllExamPaymentStatuses = require('../../../common/services/GetAllExamPaymentStatuses');

var ComplianceExaminerPaymentCollectionsTaskView = require('./ComplianceExaminerPaymentCollectionsTaskView');

module.exports = {
  event: 'build:nd:compliance-examiner-payment-collections',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();

    $.when(
      GetEntityIdentifiers(task.taskData.ND.entityId),
      GetExamDetailsForInternal(task.taskData.ND.examId),
      GetExamDetailsForEntity(task.taskData.ND.examId),
      GetExamPayment(task.taskData.ND.examPaymentId),
      GetExamPaymentsByExamId(task.taskData.ND.examId),
      GetAllExamPaymentStatuses()
    )
      .done(function(
        entityDetails,
        internalExamDetails,
        entityExamDetails,
        examPaymentDetails,
        examPayments,
        examLookups
      ) {
        NProgress.set(0.85);

        var totalAmountPaid = _.reduce(
          examPayments,
          function(totalPaid, payment) {
            if (payment.paidOnDate) {
              return totalPaid + +payment.amount;
            }
            return totalPaid;
          },
          0
        ).toFixed(2);
        var totalAmountPaidDisplay = formatAsDollars(parseFloat(totalAmountPaid));

        examPaymentDetails.daysPastDue = moment().diff(moment(examPaymentDetails.dueDate, 'MM/DD/YYYY'), 'day');

        defer.resolve(
          new ComplianceExaminerPaymentCollectionsTaskView({
            model: new Backbone.Model({
              task: task,
              taskId: task.taskId,
              entityDetails: entityDetails,
              examDetails: _.extend(entityExamDetails, internalExamDetails, {
                totalAmountPaid: totalAmountPaid,
                totalAmountPaidDisplay: totalAmountPaidDisplay
              }),
              examPaymentDetails: examPaymentDetails,
              examStatuses: examLookups.examStatuses.filter(function(status) {
                return ['4', '5', '6'].includes(status.id);
              }) //only including Paid - Late, Paid - Manually, Uncollectible
            })
          })
        );
      })
      .fail(function(err) {
        defer.reject(err);
      });

    return defer.promise();
  }
};
